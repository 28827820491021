<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Crane</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Crane No* "
                    v-model="crane.craneNumber"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Crane No'"
                    @blur="$v.crane.craneNumber.$touch()"
                    :is-valid="
                      $v.crane.craneNumber.$dirty
                        ? !$v.crane.craneNumber.$error
                        : null
                    "
                  />
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" />Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="saveSpeciality()"
            >
              <CIcon name="cil-check-circle" />Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" />Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Crane",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },

      craneId: "",
      crane: {
        craneId: "0",
        craneNumber: "",
      },

      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    crane: {
      craneNumber: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;

      this.craneId = this.$route.params.craneId;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;

      this.craneId = this.$route.params.craneId;
      this.getUser();
    }
  },

  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      this.$router.push({ name: "Cranes" });
    },

    saveSpeciality() {
      this.$v.crane.$touch();
      if (this.$v.crane.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.crane));
      } else {
        this.apiPostSecure(
          process.env.VUE_APP_API_HOST + "/emapp/web/secure/save/crane",
          this.crane
        ).then((post) => {
          this.$router.push({ name: "Cranes" });
        });
        // fetch(process.env.VUE_APP_API_HOST + "/emapp/web/save/crane", {
        //   method: "POST",
        //   headers: {
        //     "Content-type": "application/json",
        //   },
        //   body: JSON.stringify(this.crane),
        // }).then((crane) => {
        //   console.log(JSON.stringify(crane));

        //   this.$router.push({ name: "Cranes" });
        // });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/crane/" +
          this.craneId
      )
        .then((response) => response.json())
        .then((data) => (this.crane = data));
    },
    // getRoles() {
    // fetch(process.env.VUE_APP_API_HOST +"/web/secure/meta/roles")
    //     .then((response) => response.json())
    //     .then( (data)  => (this.roles = data) );
    // },
  },
  mounted() {
    //this. getRoles();
  },
};
</script>
